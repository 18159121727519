@media screen and (min-width: 800px) {
.UserCard {
    display: flex;
    flex-direction: column;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .UserCard .header {
    flex-shrink: 0;
  }
  
  .UserCard .content {
    flex-shrink: 0;
  }
  
  .UserCard .extra {
    flex-shrink: 0;
    margin-top: auto;
  }
}